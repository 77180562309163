import * as React from 'react';
import styled from 'styled-components';
import { Heading } from 'rebass/styled-components';

export const Title = styled(Heading)`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
